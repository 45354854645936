<script setup>
import { onMounted } from 'vue';
import { useFavoriteStore } from '~/stores/favorite.store';
import { useUserStore } from '~/stores/user.store';

const favoriteStore = useFavoriteStore();
const favoriteName = ref('')
const isAuthorizedModalOpen = ref(false)
const isFavoriteModalOpen = ref(false)
const userStore = useUserStore();
const user = computed(() => userStore.userGetter());
const route = useRoute();
const { $toast } = useNuxtApp()
const favoriteId = ref(null);
const config = useRuntimeConfig();

const currentUrl = new URL(route.fullPath, config.public.websiteUrl).href;
const account_url = `${config.public.accountUrl.replace(/\/$/, '')}/auth/login?redirect_to=${currentUrl}`

onMounted(async () => {
  await checkFavoriteSearchExist();
})

const addFavoriteSearch = async () => {
  await favoriteStore.addFavoriteSearch({
    name: favoriteName.value,
    user_id: 100,
    url: route.fullPath
  })
    .then((res) => {
      favoriteId.value = res.data?.id
      isFavoriteModalOpen.value = false
      $toast.addToast({
        title: res.message || "Favorite search added Successfully",
        timeout: 5000,
        position: 'top-10 right-6',
      })
    })
    .catch(err => {
      if (err?.response?.status == 401) {
        return isAuthorizedModalOpen.value = true
      }
      const message = err?.message || 'Something Went Wrong Please Try Again'
      $toast.addToast({ title: message, color: "red" })
    })
}

const checkOpenAuthOrForm = () => {
  if (user.value) {
    isFavoriteModalOpen.value = true
  } else {
    isAuthorizedModalOpen.value = true
  }
}

const removeFromFavorite = async () => {
  if (!favoriteId.value) {
    return
  }
  await favoriteStore.removeFavoriteSearch(favoriteId.value)
    .then((res) => {
      favoriteId.value = null
      $toast.addToast({
        title: res.message || "Favorite search removed Successfully",
        timeout: 5000,
        position: 'top-10 right-6',
      })
    })
    .catch((err) => {
      const message = err?.message || 'Something Went Wrong Please Try Again'
      $toast.addToast({ title: message, color: "red" })
    })
}

const authorize = () => {
  window.location.href = config.public.accountUrl;
  isAuthorizedModalOpen.value = false
}

const checkFavoriteSearchExist = async () => {
    await favoriteStore.checkExistFavoriteSearch({ url: route.fullPath || '' })
        .then((res) => favoriteId.value = res.data?.id || null)
        .catch(err => favoriteId.value = null)
}
</script>

<template>
  <div
    class="flex items-center border border-gray-200 text-gray-500 p-1 rounded hover:bg-gray-100 cursor-pointer"
    @click="favoriteId ? removeFromFavorite() : checkOpenAuthOrForm()">
    <svg xmlns="http://www.w3.org/2000/svg" :fill="favoriteId ? '#EDBD29' : 'none'" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
      class="w-5 h-5 text-yellow-500">
      <path stroke-linecap="round" stroke-linejoin="round"
        d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
    </svg>
    <span class="text-gray-600 text-sm ms-2">{{ favoriteId ? $t('client.remove_from_favorite') : $t('client.add_to_favorite') }}</span>
  </div>

  <w-modal v-model="isAuthorizedModalOpen" :transition="true" class="w-full">
    <div class="p-8 text-center flex flex-col gap-3">
      <h3 class="text-gray-900 text-2xl my-3">{{ $t("client.you_should_login_or_register_for_add_favorite") }}
      </h3>
    </div>
    <NuxtLink :to="account_url"
      class="text-lg text-white bg-indigo-900 p-3 rounded-md text-center border-0 m-5 block hover:bg-indigo-800">
      {{ $t('client.login_register') }}
    </NuxtLink>
  </w-modal>

  <w-modal v-model="isFavoriteModalOpen" :transition="true" class="w-full">
    <div class="px-8 text-center flex flex-col gap-3">
      <h3 class="text-gray-900 text-2xl my-3">{{ $t("client.add_to_favorite") }}</h3>
    </div>
    <WInput 
      class="bg-white rounded-md ring-1 ring-gray-200 border-0 my-5"
      type="text" 
      name="favorite"
      style=""
      :placeholder="$t('client.favorite_name')"
      v-model:value="favoriteName" 
    />
    <w-button class="mx-auto text-lg text-white bg-indigo-900 p-3 rounded-md text-center border-0 m-5 block hover:bg-indigo-800"
      @click="addFavoriteSearch()">
      {{ $t('client.add') }}
    </w-button>
  </w-modal>
</template>